<template>
  <div class="terms-list md:w-2/3 mx-auto fixed-draggable-dynamic-table-wrapper-height semi-box-border-y">

    <draggable-dynamic-table ref="termsListTable"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getTerms()"
                             @row:clicked="showTerm"
                             v-model="data"/>

    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="insertNewTermPromptStatus"
      @close="insertNewTermPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertTermBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('terms.insert.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="insertNewTermPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <insert-term :in-modal="true" @insert="reloadCourtsList"/>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>

    <button id="insertNewTerm" v-show="false" @click="insertNewTermPromptStatus = true"/>
  </div>
</template>

<script>
import axios from "axios";
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import InsertTerm from '../insert/insertTerm.vue'
import CustomIcon from '../../../../../components/customIcon/customIcon.vue'
import historyStates from "@/mixins/historyStates";
import {getTerms} from '@/http/requests/club/terms'
import {checkUserPermissions} from '../../../../../assets/js/functions'

export default {
  name: 'TermsList',
  metaInfo () {
    return {
      title: this.$t('terms.list.title')
    }
  },
  mixins: [historyStates],
  components: {
    CustomIcon,
    InsertTerm,
    DraggableDynamicTable
  },
  data () {
    return {
      insertNewTermPromptStatus: false,
      loadingTimer: 0,
      options: {
        id: 'termsListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'endTime',
          i18n: 'terms.list.table.header.endTime',
          width: 'calc((100% - 70px) / 4)',
          minWidth: 150,
          align: 'center',
          filter: false,
          filterType: 'date',
          sortable: true
          // locked: true
        },
        {
          field: 'startTime',
          i18n: 'terms.list.table.header.startTime',
          width: 'calc((100% - 70px) / 4)',
          minWidth: 150,
          align: 'center',
          filter: false,
          filterType: 'date',
          sortable: true
          // locked: true
        },
        {
          field: 'name',
          i18n: 'terms.list.table.header.name',
          width: 'calc((100% - 70px) / 4 * 2)',
          minWidth: 200,
          sortable: true,
          // locked: true,
          filter: false,
          filterTypes: [
            {
              icon: 'search',
              name: 'شامل شود',
              i18n: 'draggableTable.filter.types.search',
              id: 1
            }
          ]
        },
        {
          field: 'row',
          i18n: 'terms.list.table.header.row',
          width: '70px',
          minWidth: 70,
          align: 'center',
          // locked: true,
          // sortable: true,
          footer: {
            /*type: 'auto-counter'*/
          }
        }
      ],
      data: [],
      filters: [],
      selectedFilters: [],
      sorts: ['order[0]=created_at,asc'],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'insertNewTerm',
              icon: 'PLUS',
              iconPack: 'useral',
              color: 'success',
              permission: 'term.create'
            }
          ],
          leftToolbar: [
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: {name: 'termsTrash'},
              type: 'link',
              icon: 'TRASH',
              iconPack: 'useral',
              permission: 'term.delete'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
      this.$store.dispatch('updateContentNavbarClass', ' md:w-2/3 mx-auto')
    }, 50)

    if (history.state.hasOwnProperty('data') && this.$store.state.helper.prevRoute.name !== null) {
      this.getTableInfoFromHistory()
    } else {
      this.getTerms()
    }
  },
  methods: {
    checkUserPermissions(permission) {
      return checkUserPermissions(permission)
    },
    getTerms () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.termsListTable && this.data.length === 0) {
              this.$refs.termsListTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.termsListTable && this.data.length > 0) {
              this.$refs.termsListTable.loadMoreStatus = 'Loading'
            }

            getTerms(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              res.data.data.forEach((term) => {

                this.data.push({
                  route: {
                    name: 'term',
                    params: {id: term.id}
                  },
                  row: this.data.length + 1,
                  id: term.id,
                  registryDate: term.created_at,
                  gender: term.gender === 1 ? this.$t('genderTypes.man') : this.$t('genderTypes.woman'),
                  name: term.name || '',
                  startTime: term.start_time || '',
                  endTime: term.end_time || ''
                })
              })

              this.page = res.data.pagination.current_page + 1
              if (res.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('row')
                this.columnsLabel[row_index].footer.value = res.data.pagination.total
              }

              if (this.$refs.termsListTable) this.$refs.termsListTable.loadMoreStatus = ''
            })
              .catch((error) => {
                if (this.$refs.termsListTable && !axios.isCancel(error)) this.$refs.termsListTable.loadMoreStatus = 'Danger'
              })
          }
        }, 400)
    },
    showTerm (user) {
      this.setTableInfoToHistory()
    },
    setFilters (filters) {
      this.selectedFilters = filters
      const filters_list = []
      Object.keys(filters).forEach((key) => {
        switch (key) {

        case 'name':
          if (filters[key].search !== '') filters_list.push(`full_name=${  filters[key].search}`)
          break

        case 'registryDate':
          if (filters[key].search !== '') filters_list.push(`created_at=${  filters[key].search}`)
          break
        }
      })
      filters_list.push('characters=1')

      this.data = []
      this.page = 1
      this.endedList = false
      this.filters = filters_list
      this.$nextTick(() => {
        this.getTerms()
      })
    },
    setSort (columns) {
      const sorts_list = []
      Object.keys(columns).forEach((key) => {
        switch (key) {

        case 'row':
          sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'name':
          sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'startTime':
          sorts_list.push(`order[0]=start_time,${  columns[key] ? 'desc' : 'asc'}`)
          break

        case 'endTime':
          sorts_list.push(`order[0]=start_time,${  columns[key] ? 'desc' : 'asc'}`)
          break
        }
      })

      if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

      this.data = []
      this.page = 1
      this.endedList = false
      this.sorts = sorts_list
      this.$nextTick(() => {
        this.getTerms()
      })
    },
    reloadCourtsList () {
      this.data = []
      this.page = 1
      this.endedList = false
      this.getTerms()
      this.insertNewTermPromptStatus = false
    },
    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style scoped>
</style>
